import React, { useState, useEffect } from "react";
import "./CompetitionsPage.css"; // Import the CSS file

const CompetitionsPage: React.FC = () => {
  const [competitions, setCompetitions] = useState([]); // State to store the competitions

  // Fetch your list of competitions and store them in state
  useEffect(() => {
    const cookie = getCookie("jwt");
    if (cookie === null || cookie === undefined) {
      window.location.href = "/";
    } else {
      getCompetitions(cookie);
    }
  }, []);

  const getCookie = (name: string): string | null => {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  };

  const getCompetitions = async (token: string) => {
    try {
      const competitionsData = await fetch(
        "https://api.timebase.live/users/competitions?token=" +
          token +
          "&redirect_uri=https://admin.timebase.live"
      );
      const competitions = await competitionsData.json();
      setCompetitions(competitions);
    } catch (error) {
      console.error("Error fetching competitions:", error);
    }
  };

  // Handle clicking on a CompetitionCard
  const handleCardClick = (competitionId: string) => {
    // Navigate to the specific route based on the competition ID
    window.location.href = `/${competitionId}`;
  };

  return (
    <div>
      <h2>Select a Competition</h2>
      <div className="competition-cards">
        {competitions.map((competition: any, index) => (
          <CompetitionCard
            key={index}
            competition={competition}
            onClick={() => handleCardClick(competition.id)}
          />
        ))}
      </div>
    </div>
  );
};

const CompetitionCard: React.FC<{ competition: any; onClick: () => void }> = ({
  competition,
  onClick,
}) => {
  return (
    <div className="competition-card" onClick={onClick}>
      <h3>{competition.name}</h3>
    </div>
  );
};

export default CompetitionsPage;
