import React, { useState } from "react";
import "./CompetitionsPage.css"; // Import the CSS file

const ChaChamps: React.FC = () => {
  const [competitorName, setCompetitorName] = useState(""); // State to store the competitor name input
  const [competitionData, setCompetitionData] = useState<any>(null); // State to store the fetched competition data
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [error, setError] = useState<string | null>(null); // State to manage error messages

  // Function to handle input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompetitorName(event.target.value);
  };


  const getCookie = (name: string): string | null => {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  };


  // Function to handle form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission behavior
    setLoading(true);
    setError(null);

    try {

      fetch(`https://api.timebase.live/users/chachamps?name=${competitorName}&token=${getCookie("jwt")}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      })
        .then(async (res) => {
          // TODO: Handle 400's and 500's
          console.log(await res.text());
          const competition = await res.json();

          // TODO: please dear god dont send a network request every time someone taps a nametag plz ty
          setCompetitionData(competition);
        })
        .catch((err) => console.error(err));
    // }



      // const response = await fetch(`https://api.timebase.live/users/chachamps?name=${competitorName}&token=${getCookie("jwt")}`);
      // if (!response.ok) {
      //   throw new Error("Failed to fetch data");
      // }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="competition-container">
      <h2>Enter Competitor Name</h2>
      <form onSubmit={handleSubmit} className="competition-form">
        <input
          type="text"
          value={competitorName}
          onChange={handleInputChange}
          placeholder="Enter Competitor Name"
          className="competition-input"
          required
        />
        <button type="submit" disabled={loading} className="competition-button">
          {loading ? "Loading..." : "Submit"}
        </button>
      </form>

      {error && <p className="error-message">{error}</p>}

      {competitionData && (
        <div className="competition-details">
          <h3>{competitionData.name}</h3>
          {/* Display other competition details as needed */}
        </div>
      )}
    </div>
  );
};

export default ChaChamps;
