import React, { useState } from "react";

const LoginPage: React.FC = () => {
  const getCookie = (name: string): string | null => {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  };

  const [loggedIn, setLoggedIn] = useState(false);
  if (getCookie("jwt") !== null) {
    return (window.location.href = "/competitions");
  }
  const handleLogin = () => {
    // Add your login logic here.
    // Set loggedIn to true upon successful login.
    window.location.href =
      "https://www.worldcubeassociation.org/oauth/authorize?client_id=yrJNHmXnJQn7E4v2z1vDsOTADJjIQj4733_QZhAJc9k&redirect_uri=https%3A%2F%2Fadmin.timebase.live&response_type=code&scope=manage_competitions+email+dob+public";
    setLoggedIn(true);
  };

  return (
    <div>
      {!loggedIn ? (
        <button onClick={handleLogin}>Login</button>
      ) : (
        <div>{/* Code for the competitions page goes here */}</div>
      )}
    </div>
  );
};

export default LoginPage;
