import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./LoginPage";
import ChaChamps from "./ChaChamps";
import CompetitionsPage from "./CompetitionsPage";
import NamesListPage from "./NamesListPage";

function App() {
  const currentUrl = window.location.href;
  const [jwt, setJwt] = useState("");
  const login = async (code: string) => {
    // const redirectUri = "http://localhost:1234/"; //local
    const redirectUri = "https://admin.timebase.live"; // prod
    const queryParams = new URLSearchParams();
    queryParams.set("token", code);
    queryParams.set("redirect_uri", redirectUri);

    const apiUrl = `https://api.timebase.live/users/login?${queryParams.toString()}`;

    let jwt = (await (await fetch(apiUrl, { method: "POST" })).json()).jwt;
    document.cookie = `jwt=${jwt}`;
  };

  const url = new URL(currentUrl);

  const code = url.searchParams.get("code");
  if (code !== null && code !== undefined) {
    login(code);
  }
  console.log(code);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/competitions" element={<CompetitionsPage />} />
        <Route path="/:id" element={<NamesListPage />} />
        <Route path="/chachamps" element={<ChaChamps />} />
      </Routes>
    </Router>
  );
}

export default App;
