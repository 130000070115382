import React, { useState, useEffect } from "react";
import "./CompetitionsPage.css"; // Import the CSS file for the component
import { useParams } from "react-router-dom";
interface ActiveMapping {
  wca_live_id: string;
  competition_id: string;
}
const NamesListPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { id } = useParams();
  const [names, setNames] = useState([]);
  const [error, setError] = useState(null);
  const [activeMapping, setActiveMapping] = useState<ActiveMapping | null>(
    null
  );

  const getCookie = (name: string): string | null => {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  };

  const getCompetitors = async (token: string, competitionId: string) => {
    try {
      const competitionsData = await fetch(
        "https://api.timebase.live/users/competitors?token=" +
          token +
          "&redirect_uri=https://admin.timebase.live&competition_id=" +
          competitionId
      );
      const competitions = await competitionsData.json();
      setNames(competitions);
    } catch (error) {
      setError(error);
      console.error("Error fetching competitions:", error);
    }
  };

  const handleCardClick = (nameInfo: any) => {
    setActiveMapping((prevActiveMapping) => {
      console.log("Clicked on card:", nameInfo.wca_live_id);
      if (
        prevActiveMapping &&
        prevActiveMapping.wca_live_id === nameInfo.wca_live_id
      ) {
        console.log("Setting activeMapping to null");
        return null;
      } else {
        console.log("Setting activeMapping to", nameInfo.wca_live_id);
        return nameInfo;
      }
    });
  };

  function onTagId(tag_id: any, reader: any) {
    if (!activeMapping) {
      console.log("plz select something buddy");
      return;
    }

    console.log("onTagId: activeMapping =", activeMapping.competition_id);

    const cookie = getCookie("jwt");

    if (!cookie) {
      console.error("No cookie");
      return;
    }

    fetch("https://api.timebase.live/users/map/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: cookie,
        tag_id: tag_id,
        competitor_id: activeMapping.wca_live_id,
        competition_id: activeMapping.competition_id,
      }),
    })
      .then(async (res) => {
        // TODO: Handle 400's and 500's
        console.log(await res.text());

        // TODO: please dear god dont send a network request every time someone taps a nametag plz ty
        getCompetitors(cookie, id!);
      })
      .catch((err) => console.error(err));
  }

  const handleTagScanned = (e: CustomEvent) => {
    console.log("Got tag id: " + e.detail.tag_id);
    onTagId(e.detail.tag_id, e.detail.reader);
  };

  useEffect(() => {
    document.addEventListener("TB_tag-scanned", handleTagScanned);

    return () => {
      document.removeEventListener("TB_tag-scanned", handleTagScanned);
    };
  }, [activeMapping]);

  useEffect(() => {
    const cookie = getCookie("jwt");
    if (cookie === null || cookie === undefined) {
      window.location.href = "/";
    } else {
      getCompetitors(cookie, id!);
    }
  }, [id]);

  return (
    <div>
      <input
        type="text"
        placeholder="Search for a name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <ul className="names-list">
        {names
          .filter((nameInfo: any) =>
            nameInfo.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((nameInfo: any, index) => (
            <li
              key={index}
              className={`name-card ${
                activeMapping === nameInfo
                  ? "active-card"
                  : nameInfo.tag_id
                  ? "green-card"
                  : ""
              }`}
              onClick={() => handleCardClick(nameInfo)}
            >
              <div>Name: {nameInfo.name}</div>
              <div>WCA ID: {nameInfo.wca_id || "N/A"}</div>
              <div>ID: {nameInfo.wca_live_id}</div>
            </li>
          ))}
      </ul>
      <div>
        <p>
          Active Mapping: {activeMapping ? activeMapping.wca_live_id : "null"}
        </p>
      </div>
    </div>
  );
};

export default NamesListPage;
